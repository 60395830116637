<template>
  <div class="ml-5 mr-5 pl-4 mt-10 secondary">
    <v-row
      class="ml-5 pt-3"
      v-if="showtop"
    >
      <v-icon
        large
        left
        color="success"
        class="mb-2"
      > {{ dbicon }} </v-icon>
      <v-tooltip
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="search = '', getMonths('')"
            v-on="on"
            slot="activator"
          >
            <v-icon
              small
              left
            >mdi-sort</v-icon>
            <span class="accent--text text-lowercase text-h6">By Months</span>
          </v-btn>
        </template>
        <span>By Months</span>
      </v-tooltip>
      <v-tooltip
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="selectItem('Enter any part of title', 'title')"
            v-on="on"
            slot="activator"
          >
            <v-icon
              small
              left
            >search</v-icon>
            <span class="accent--text text-lowercase text-h6">Filter Title</span>
          </v-btn>
        </template>
        <span>Search by Title</span>
      </v-tooltip>
      <v-tooltip
        v-if="which=='M'"
        color="success"
        top
      >
        <template v-slot:activator="{ on }">
          <v-btn
            small
            text
            color="success"
            @click="selectItem('Enter two digit month', 'date')"
            v-on="on"
            slot="activator"
          >
            <v-icon
              small
              left
            >search</v-icon>
            <span class="accent--text text-lowercase text-h6">Filter Month</span>
          </v-btn>
        </template>
        <span>Search by Month</span>
      </v-tooltip>
      <p
        v-if="search"
        class="ml-5"
      >
        <span
          @click="serOff()"
          class="accent--text searchbar"
        >Filter: </span>
        <span class="black-text font-weight-bold">{{
          search.substr(0, 50)
        }}</span>
      </p>
    </v-row>
    <div
      class="mt-5"
      v-for="(loc, index) in locs"
      :key="index"
    >
      <h3
        v-if="loc.pics"
        class="success--text font-italic font-weight-medium pl-0 pb-2 ml-3 text-h6"
      >
        {{ loc.loc }}
      </h3>
      <h4 class="ml-3 success--text">Pics: {{ loc.pics }} Species: {{ loc.species }}</h4>
      <br>
      <v-row
        no-gutters
        class="ml-3 tasks"
        v-for="(project, index) in projects"
        :key="project.index"
      >
        <v-col
          v-if="project.loc == loc.loc"
          sm="10"
          md="11"
          lg="4"
          xl="3"
        >
          <p class="lineh">{{ project.title }}</p>
        </v-col>
        <v-col
          v-if="project.loc == loc.loc"
          sm="10"
          md="11"
          lg="4"
          xl="2"
          class="text-h6"
        >
          <p class="lineh text-right">{{ project.locnum }}</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { shared } from "../mixins/shared";
import store from "../store/store.js";

export default {
  mixins: [shared],
  data() {
    return {
      dbicon: "",
      projects: [],
      locs: [],
      menu: false,
      curdoc: [],
      showtop: true,
      search: "",
      which: "L",
      filter: "title",
      thresh: 80,
      url: "https://vsgpartners.com/data.php",
    };
  },
  methods: {
    selectItem(cue, fil) {
      this.search = prompt(cue);
      this.filter = fil;
      this.getMonths(this.filter);
    },
    getMonths(fld) {
      this.which = "M";
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "displaym",
        search: this.search,
        fld: fld,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.projects = data;
          this.projects.sort(function (a, b) {
            return a.loc.localeCompare(b.loc) || b.locnum - a.locnum;
          });
          this.getDates(fld);
        })
        .catch((error) => alert(error));
    },
    getLocs(fld) {
      this.locs = [];
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "locs",
        search: this.search,
        fld: fld,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.locs = data;
          this.locs.sort(function (a, b) {
            return b.pics - a.pics;
          });
        })
        .catch((error) => alert(error));
    },
    getDates(fld) {
      this.locs = [];
      this.which = "M";
      var url = this.url;
      var db = store.durl;
      var formdata = {
        action: "dates",
        search: this.search,
        fld: fld,
        db: db,
      };
      this.axios
        .post(url, formdata, {
          headers: {
            "Content-Type": "text/plain",
          },
        })
        .then((res) => {
          const data = res.data;
          this.locs = data;
        })
        .catch((error) => alert(error));
    },
    serOff() {
      this.search = "";
      this.getMonths("title");
    },
  },
  created() {
    this.getMonths(this.filter);
  },
};
</script>

<style scoped>
.filter:hover {
  background-color: orange;
  cursor: pointer;
  white-space: normal;
  width: auto;
}
.img-click2 {
  margin-left: 15%;
}
.img-click2:hover {
  transform: scale(4);
  z-index: 10;
}

.value {
  font-size: 1.3rem;
  color: #330000;
}
.down {
  display: hidden;
}

.column_wrapper:hover {
  padding-left: 5px;
  box-shadow: 0 0px 0px red, 0 5px 5px red;
  cursor: pointer;
}

.img-one {
  border-radius: 10%;
}

.lineh {
  line-height: 1;
}
</style>
